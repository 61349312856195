import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Button } from "components/anti/buttons/buttons"
import { useScrollAnim } from "src/components/hooks/hooks"

import bpjsGfx from "assets/img/bpjs/bpjs-gfx.svg"

export const BpjsRegisterPage = ({ pageData }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section
      className="py-main sc-bpjs-register sc-bpjs-prosedur sc-bpjs-benefits"
      ref={trigger}
    >
      <div className="container mw-xl">
        <Button
          variant="link"
          className="btn-back-help ai-arrow-from-left mb-3"
          link={"/bpjskesehatan"}
        >
          Kembali
        </Button>
        <h1 className="mb-5">{pageData.title}</h1>
      </div>
      <div className="container mw-lg">
        <ul className="prosedur-list">
          {pageData.list.map((list, i) => {
            return (
              <li
                className={`prosedur-item d-flex align-items-center ${anim(
                  1 + i
                )}`}
                key={i}
              >
                <div className="circle">
                  <h2 className="mb-0">0{i + 1}</h2>
                </div>
                <div>
                  <h5>{list.title}</h5>
                  <p>{list.text}</p>
                  {list.button.text && (
                    <div className="row">
                      <div className="col-md-12">
                        <Button variant="primary" link={list.button.url}>
                          {list.button.text}
                        </Button>
                      </div>
                      {/* <Link className="mx-2">
                                                <img
                                                    src={appStore}
                                                    className="img-fluid h-50px"
                                                    alt="App Store"
                                                />
                                            </Link>
                                            <Link>
                                                <img
                                                    src={playStore}
                                                    className="img-fluid h-50px"
                                                    alt="Play Store"
                                                />
                                            </Link> */}
                    </div>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <img src={bpjsGfx} alt="gfx" className="img-fluid bpjs-gfx" />
    </section>
  )
}
