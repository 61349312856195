import React from "react"

import Layout from "components/layout"
import { BpjsRegisterPage } from "components/pages/bpjs/register/register"
import Seo from "components/seo"
import { graphql, useStaticQuery } from "gatsby"

const BpjsRegister = () => {
  const data = useStaticQuery(GET_BPJS_REGISTER)

  const pageData = data.wordPress.page.bpjsRegisterPage.bpjsRegister

  return (
    <Layout>
      <Seo title="Register BPJS" />
      <BpjsRegisterPage pageData={pageData} />
    </Layout>
  )
}

export default BpjsRegister

const GET_BPJS_REGISTER = graphql`
  query BPJSRegister {
    wordPress {
      page(id: "bpjs-register", idType: URI) {
        title
        bpjsRegisterPage {
          bpjsRegister {
            title
            list {
              title
              text
              button {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`
